import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { comma, concatGeos } from "./helpers"
import { Line } from "react-chartjs-2"
import _ from "lodash"
import "chartjs-plugin-datalabels"
import Source from "./source"

export default function PermitsLine({geos}) {

  const q = useStaticQuery(graphql`
    {
      allBuildingPermitsCsv {
        nodes {
          Town
          _1990
          _1991
          _1992
          _1993
          _1994
          _1995
          _1996
          _1997
          _1998
          _1999
          _2000
          _2001
          _2002
          _2003
          _2004
          _2005
          _2006
          _2007
          _2008
          _2009
          _2010
          _2011
          _2012
          _2013
          _2014
          _2015
          _2016
          _2017
          _2018
          _2019
          _2020
          _2021
          _2022
          _2023
        }
      }
    }
  `)

  // Get data for geographies only, convert income to integers
  const dataArray = q.allBuildingPermitsCsv.nodes

  const data = _.keyBy(dataArray, 'Town')

  const years = _.range(1990, 2024)
  const values = years.map(function(year) { return data[geos[0]]['_' + year] })

  const maxValue = _.max( values.map(x => parseInt(x)) )
  
  let maxValueYears = []
  for (var i in values) {
    if (parseInt(values[i]) === maxValue) {
      maxValueYears.push(years[i])
    }
  }

  return <div className="cf">

    <p className="measure-wide mb4">
      The line chart below shows annual building permits issued in {geos[0]} since 1990.
      {
        maxValue > 0
          ? <span> The maximum of {maxValue} permits were issued in {concatGeos(maxValueYears)}.</span>
          : ''
      }
    </p>


    <div className="relative">

      <Line
        height={250}
        data={{
          labels: years,
          datasets: [{
            label: 'Building permits issued in ' + geos[0] + ', CT',
            borderColor: '#9F88DE',
            backgroundColor: '#9F88DE',
            fill: false,
            data: values,
            //pointRadius: 0
          }]
        }}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              display: true,
              gridLines: {display: true, lineWidth: 1, zeroLineWidth: 1, zeroLineColor: 'rgba(0,0,0,0.1)'},
              ticks: {display: true, callback: function(x) {return comma(x)}, beginAtZero: false},
            }],
            xAxes: [{
              display: true,
              gridLines: {display: false}
            }]
          },
          legend: {
            display: true,
            onClick: null
          },
          tooltips: {
            callbacks: {
              label: function(item, tooltipData) {
                return comma(item.value) + ' permits'
              }
            }
          },
          
          plugins: {
            datalabels: {
              display: false
            }
          }
        }}
      />

    </div>

    <div className="cf"></div>

    <Source ctdata>
      <a href="https://portal.ct.gov/DECD/Content/About_DECD/Research-and-Publications/01_Access-Research/Exports-and-Housing-and-Income-Data" className="link silver underline dim">
        Annual Housing Permit Data, DECD
      </a>
    </Source>

  </div>

}