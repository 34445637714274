import React, {useEffect, useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Select from "react-select"
import Layout from "../components/layout"

import KeyFindings from "../components/key-findings"
import PopulationTable from "../components/population-table"
import PopulationLine from "../components/population-line"
import PopulationChange from "../components/population-change"
import PopulationRace from "../components/population-race"
import PopulationAge from "../components/population-age"
import MedianHHIncome from "../components/median-hh-income"
import MedianRent from "../components/median-rent"

import HousingUnitsTable from "../components/housing-units-table"
import HousingUnitsAge from "../components/housing-units-age"
import HousingCosts from "../components/housing-costs"
import HousingWage from "../components/housing-wage"
import HousingUnitsAffordableTable from "../components/housing-units-affordable-table"
import HousingBedrooms from "../components/housing-bedrooms"
import HousingUnitsOccupancy from "../components/housing-units-occupancy"
import ZillowHomeValues from "../components/zillow-home-values"
import BuildingPermitsTable from "../components/building-permits-table"
import HousingUnitsHeatingFuel from "../components/housing-units-heating-fuel"
import PermitsLine from "../components/permits-line"
import HousingPreservationTable from "../components/housing-preservation-table"
import HousingUnitsByRent from '../components/housing-units-by-rent'

import _ from "lodash"

import HomesSvg from "../images/homes.svg"
import TownSvg from "../images/town.svg"
import AffordabilitySvg from "../images/affordability.svg"
import Download from "../images/download.svg"

import geos from "../data/geos"
import Housing211Calls from "../components/housing-211"

export default function Profile() {

  const q = useStaticQuery(graphql`
    {
      allTown2CogCsv {
        nodes {
          Town
          COG
        }
      }
    }
  `)

  const town2county = _.keyBy(q.allTown2CogCsv.nodes, 'Town')

  const urlTown = typeof window !== 'undefined' ? window.location.href.split('#').pop().replace('%20', ' ') : ''
  const initialTown = (urlTown && town2county[urlTown]) ? urlTown : 'Hartford'

  // Keep track of selected geographies
  const [town, setTown] = useState( initialTown )
  const [geosSelected, setGeos] = useState([])

  const profileUrl = 'https://housing-profiles.s3.amazonaws.com/2024/' + town + '.pdf';
  const profileUrl22 = 'https://housing-profiles-2022.s3.amazonaws.com/2022/' + town + '.pdf';
  const profileUrl20 = 'https://housing-profiles.s3.amazonaws.com/2020/' + town + '.pdf';
  const cercProfileUrl = 'https://s3-us-west-2.amazonaws.com/cerc-pdfs/2024/' + town.replace(' ', '-') + '.pdf';

  // Once components are rendered, update geosSelected
  useEffect(() => {
    if (geosSelected.length === 0 || geosSelected[0] !== town) {
      setGeos([
        town,
        town2county[town].COG, // We are now using COGs, not old Counties!
        'Connecticut'
      ])

      document.getElementById('pdf-profile').setAttribute('href', profileUrl)
      document.getElementById('pdf-profile-22').setAttribute('href', profileUrl22)
      document.getElementById('pdf-profile-20').setAttribute('href', profileUrl20)
      document.getElementById('pdf-cerc-profile').setAttribute(
        'href',
        town === 'Canaan' ? cercProfileUrl.replace('Canaan', 'Canaan-FallsVillage') : cercProfileUrl
      )
    }
  }, [town, geosSelected, town2county, profileUrl, profileUrl22, profileUrl20, cercProfileUrl])

  return <Layout>

    <h4 className="f4 mb0 pb2 pt2 pl2 bg-near-white">
      Pick a town from the dropdown
    </h4>

    <div style={{position: 'sticky', top: '0px', zIndex: 9999}} className="pv1 ph2 bg-near-white cf">
      <Select
        defaultValue={[{value: initialTown, 'label': initialTown}]}
        name='geosSelected'
        className="w-50-l fl-l"
        options={
          _.sortBy(geos.slice(10,), 'value')
        }
        onChange={function(val) {
          setTown(val.value);
          if (typeof window !== 'undefined') {
            window.location.href = window.location.href.split('#')[0] + '#' + val.value
          }
        }}
      />

      <p className="f5 black-50 mt1 mt0-l mb1 w-50-l fl-l pl3-l lh-solid">
        <b>Jump to</b>
          {} <a href="#housing" className="link underline black-50 dim">Housing</a>,
          {} <a href="#affordability" className="link underline black-50 dim">Affordability</a>, 
          or <a href="#population" className="link underline black-50 dim">Population</a>,
          or <a href="/compare" className="link underline black-50 dim">switch to comparison interface</a>.
      </p>

    </div>

    <div className="bg-white pa2 br3 mt3 ph3 ba b--black-20">
      <b><img src={Download} className="h1" alt="Download icon" /> Download as a PDF</b>
      <ul className="list lh-copy mt1 mb0 pl0">
        <li>
          <a href={profileUrl} id="pdf-profile" className="link dark-blue dim">2024 Housing Profile for {town}</a> 
          &nbsp;(see also <a href={profileUrl22} id="pdf-profile-22" className="link dark-blue dim">2022</a>
            &nbsp;and <a href={profileUrl20} id="pdf-profile-20" className="link dark-blue dim">2020</a>
          )
        </li>
        <li>
          <a href={cercProfileUrl} id="pdf-cerc-profile" className="link dark-blue dim">
            2024 CERC Town Profile for {town}
          </a>
        </li>
      </ul>
    </div>

    <img src={HomesSvg} alt="Houses" className="mt5 w-100" />

    {geosSelected.length > 0 ?
    <>

      <h2 id="keyfindings" className="f2 mt0 pt5 mb3 bb bw4" style={{borderColor: '#15397F'}}> Key Findings </h2>
      <KeyFindings town={geosSelected[0]} />

      {/* HOUSING */}
      <h2 id="housing" className="f2 mt0 pt5 mb0 bb bw4" style={{borderColor: '#9F88DE'}}> Housing </h2>

      <h3 className="f4 mt4 mb0">Units in Structure</h3>
      <HousingUnitsTable geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Bedrooms</h3>
      <HousingBedrooms isProfile geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Occupancy Status</h3>
      <HousingUnitsOccupancy isProfile geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Age of Units</h3>
      <HousingUnitsAge geos={geosSelected} isProfile />

      <h3 className="f4 mt4 mb0">Units by Gross Rent</h3>
      <HousingUnitsByRent isProfile geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Building Permits</h3>
      <BuildingPermitsTable geos={geosSelected} />

      <h4 className="f5 mt4 mb0">Permits Issued Since 1990</h4>
      <PermitsLine geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Heating Fuel</h3>
      <HousingUnitsHeatingFuel geos={geosSelected} />


      {/* AFFORDABILITY */}
      <h2 id="affordability" className="f2 pt5 mb3 bb bw4" style={{borderColor: '#346E51'}}>Affordability</h2>
      <img src={AffordabilitySvg} width='100%' alt="Affordable neighborhood illustration" />

      <h3 className="f4 mt4 mb0">Housing Wage</h3>
      <HousingWage isProfile geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Assisted Housing Units</h3>
      <HousingUnitsAffordableTable isProfile geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Median Rent</h3>
      <MedianRent isProfile geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Housing Costs</h3>
      <HousingCosts geos={geosSelected} isProfile />

      <h3 className="f4 mt4 mb0">Home Values</h3>
      <ZillowHomeValues isProfile geos={[geosSelected[0], geosSelected[2]]} />

      <h3 className="f4 mt4 mb0">Housing Preservation Units</h3>
      <HousingPreservationTable isProfile geos={geosSelected} />


      {/* POPULATION */}
      <h2 id="population" className="f2 pt5 mb3 bb bw4" style={{borderColor: '#F08A43'}}> Population </h2>
      <img src={TownSvg} className="mb3 w-100" alt="City street with people and cars" />

      <PopulationTable isProfile geos={geosSelected} />

      <h3 className="f4 mt4 mb0">{geosSelected[0]}'s Population Since 2000</h3>
      <PopulationLine geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Population Change In Past 3 Years</h3>
      <PopulationChange geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Population by Age</h3>
      <PopulationAge geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Population by Race</h3>
      <PopulationRace geos={geosSelected} />

      <h3 className="f4 mt4 mb0">Median Household Income</h3>
      <MedianHHIncome isProfile geos={geosSelected} />

      <h3 className="f4 mt4 mb0">2-1-1 Calls</h3>
      <Housing211Calls geos={geosSelected} isProfile />

    </>

    : <div className="bg-light-gray pv6 mv5 f3 b tc black-40">
      Choose at least one geography from the dropdown
    </div>
  }

  </Layout>
}
